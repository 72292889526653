<template>
  <div v-if="quantityRemaining >= count" class="relative inline-flex -mr-2">
    <div class="flex flex-col">
      <div v-if="soldOut" class="flex">
        <button
          class="inline-block w-24 h-8 ml-1 text-gray-brand-700 text-xs font-medium bg-gray-brand-300 rounded-md tracking-tight"
          :disabled="true"
        >
          Sold Out
        </button>
      </div>
      <div v-else-if="type === 'default'" class="flex">
        <button
          v-if="count > 0"
          class="transition-all inline-block w-10 h-8 p-2 px-3 mr-1 rounded-lg items-center text-gray-brand-500 hover:text-gray-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-800"
          @click="decreaseCount()"
        >
          <svg
            v-if="count > 1"
            class="w-full fill-current"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M20 12H4"></path>
          </svg>
          <TrashIcon v-else class="w-full fill-current" />
        </button>
        <div className="flex rounded-md shadow-sm group">
          <input
            type="text"
            inputmode="numeric"
            placeholder="0"
            class="transition-all peer w-8 h-8 text-center md:hover:border-gray-brand-600 border rounded-l-lg font-inter font-semibold focus:outline-none focus:ring-2 focus:ring-brand-800"
            :value="countForValue"
            :class="[
              count > 0
                ? 'bg-gray-brand-600 text-gray-brand-100 border-gray-brand-500'
                : 'bg-gray-brand-200 text-gray-brand-500 border-none',
              hasUniqueName ? '' : 'rounded-r-lg'
            ]"
            @change="set($event.target.value)"
          />
          <span
            v-if="hasUniqueName"
            class="transition-all inline-flex items-center rounded-r-lg border-l border-gray-300 px-2.5 text-gray-500 sm:text-sm peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-brand-800"
            :class="[
              count > 0
                ? 'bg-gray-brand-600 text-gray-brand-100 border-gray-brand-500'
                : 'bg-gray-brand-200 text-gray-brand-600'
            ]"
          >
            {{
              count == 1
                ? product?.attributes?.unique_quantity_name_single
                : product?.attributes?.unique_quantity_name_plural
            }}
          </span>
        </div>
        <button
          v-if="noneLeft"
          class="transition-all inline-block w-24 h-8 ml-1 text-gray-brand-700 text-xs font-medium bg-gray-brand-300 rounded-md tracking-tight"
          :disabled="true"
        >
          {{ beyondSoldOut ? 'Sold Out' : 'Max Available' }}
        </button>
        <button
          v-else
          class="transition-all inline-flex ml-1 p-2 px-3 rounded-lg items-center text-gray-brand-500 hover:text-gray-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-800"
          :class="count === 0 ? 'pt-1.5' : 'pt-2'"
          :disabled="noneLeft"
          @click="increaseCount()"
        >
          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
          <span v-if="count === 0" class="ml-1.5 text-sm text-gray-brand-600 font-semibold">Add to cart</span>
        </button>
      </div>
      <div v-else class="flex items-center">
        <input
          :id="checkboxKey"
          type="checkbox"
          class="transition-all focus:ring-brand-500 h-5 w-5 accent-brand-600 border-gray-300 rounded cursor-pointer"
          :checked="count > 0"
          @input="set($event.target.checked ? 1 : 0)"
        />
        <label
          :for="checkboxKey"
          class="ml-2 mr-1 select-none text-sm cursor-pointer font-semibold tracking-tighter text-gray-brand-700"
        >
          Add{{ count > 0 ? 'ed' : '' }} to cart
        </label>
      </div>
    </div>
  </div>
  <div v-else-if="!isActivelySubmittingCart" class="relative inline-flex -mr-2">
    <button
      class="transition-all h-8 p-2 px-3 mr-1 rounded-lg inline-flex items-center text-sm text-white bg-gray-brand-600 font-bold hover:text-gray-brand-500"
      @click="set(0)"
    >
      Remove {{ count }} item{{ count > 1 ? 's' : '' }} (sold out)
    </button>
  </div>
  <div v-else-if="isActivelySubmittingCart" class="relative inline-flex -mr-2">
    <label
      class="transition-all ml-2 mr-1 select-none text-sm cursor-pointer font-semibold tracking-tighter text-gray-brand-700"
    >
      Updating cart...
    </label>
  </div>
</template>
<script>
import { ulid } from 'ulid'
import { mapGetters } from 'vuex'
import { TrashIcon } from '@heroicons/vue/solid'

export default {
  components: {
    TrashIcon
  },
  props: {
    product: {
      required: true
    },
    submittableCartItem: {
      required: false
    },
    categoryProductStorePair: {
      required: false
    }
  },
  data() {
    return { checkboxKey: ulid() }
  },
  computed: {
    ...mapGetters(['info']),
    countForValue() {
      return this.count == 0 ? null : this.count
    },
    count() {
      return this.submittableCartItem?.quantity || 0
    },
    isActivelySubmittingCart() {
      return this.$store.state.activelySubmittingCart
    },
    quantityRemainingRaw() {
      return this.categoryProductStorePair?.attributes?.quantity_remaining_raw
    },
    quantityRemaining() {
      return this.categoryProductStorePair?.attributes?.quantity_remaining + this.count
    },
    soldOut() {
      return this.count == 0 && this.quantityRemaining <= 0
    },
    beyondSoldOut() {
      return this.count > 0 && this.quantityRemainingRaw < 0
    },
    noneLeft() {
      return this.quantityRemaining - this.count <= 0
    },
    hasUniqueName() {
      return this.product?.attributes?.unique_quantity_name_single
    },
    step() {
      return this.product?.attributes?.step || 1
    },
    startingQuantity() {
      return this.product?.attributes?.starting_quantity || 1
    },
    type() {
      return this.categoryProductStorePair?.attributes?.quantity_selector_type
    },
    facebookPixelContainerID() {
      return this.info?.attributes?.facebook_pixel_container_id
    }
  },
  methods: {
    decreaseCount() {
      if (this.count == 0) return
      if (this.count - this.step < this.startingQuantity) {
        this.set(0)
      } else {
        this.set(this.count - this.step)
      }
    },
    increaseCount() {
      this.set(Math.max(this.count + this.step, this.startingQuantity))
    },
    set(quantity) {
      if (quantity > 0) {
        this.handleTracking(quantity)
      }
      this.$store.dispatch('updateSubmittableCart', {
        submittableCartItem: Object.assign({}, this.submittableCartItem, { quantity })
      })
    },
    handleTracking(quantity) {
      window.bottle.hooks.v1.emit('add_to_cart', {
        // TODO(alicja): need to get total price but we don't have a cart_item at this point yet
        // currency:
        // value:
        items: [
          {
            item_id: this.product.attributes.id,
            item_name: this.product.attributes.product_name,
            // price:
            quantity
          }
        ]
      })
      if (this.facebookPixelContainerID) {
        window.fbq('track', 'AddToCart', {
          product_name: this.product?.attributes?.product_name,
          quantity: quantity,
          product_id: this.product?.id
        })
      }
    }
  }
}
</script>
