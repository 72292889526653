<template>
  <div
    class="mt-4 md:mt-6 bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 overflow-hidden"
  >
    <TippingSection v-if="showTippingSection" :bottle="bottle" :checkout="checkout" />
    <DiscountCodeModule
      v-if="hasDiscountCodeBox"
      @discount-updated="updateDiscount"
      @discount-removed="removeDiscount"
    />
    <div v-show="showPaymentNoticeBox" class="p-4 py-4 border-l border-r border-t">{{ checkoutNotice }}</div>
    <div v-show="showMarkAsPaidBox" class="p-4 py-4 border-l border-r border-t">
      <div class="">
        <PrimaryButton
          @click="markAsPaid"
          class="w-full"
          :size="'xl'"
          :justify="'justify-center'"
          :streaking="true"
          :is-loading="pendingPayment"
        >
          Pay with {{ checkout?.attributes?.account_credit_amount?.format }} of Account Credit
        </PrimaryButton>
      </div>
    </div>
    <div v-show="showElementsPaymentMethod && !hasCheckoutErrors && !showMarkAsPaidBox" class="p-4 w-full border-t">
      <fieldset>
        <div v-fade-in ref="paymentrequestbutton"></div>
        <div v-fade-in v-if="applePaySupported" class="relative py-3.5">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="bg-white px-2 text-sm text-gray-500">Or, add a card</span>
          </div>
        </div>
        <div v-fade-in ref="payment" class="pb-2"></div>
        <div
          v-show="hasPaymentErrors"
          id="card-errors"
          role="alert"
          class="mt-1 text-red-600 font-semibold text-sm rounded-md px-3 pt-1 pb-2 text-center"
        >
          {{ paymentErrorMessages[paymentErrorMessages.length - 1] }}
        </div>
        <div class="mt-2">
          <PrimaryButton
            @click="pay"
            class="w-full"
            :size="'xl'"
            :justify="'justify-center'"
            :streaking="canPay"
            :active="canPay"
            :is-loading="pendingPayment"
          >
            Pay {{ chargeableAmountCents() }}
          </PrimaryButton>
          <SecondaryButton
            v-if="hasExistingStripePaymentMethods"
            @click="addADifferentPaymentMethod = false"
            class="w-full mt-2"
            :size="'xl'"
          >
            Choose a payment method on file
          </SecondaryButton>
        </div>
      </fieldset>
    </div>
    <div v-show="showExistingStripePaymentMethods && !showPaymentNoticeBox && !showMarkAsPaidBox" class="border-t">
      <InfoRow
        icon-slug=""
        :main-text="stripePaymentMethodToUseTitle"
        :secondary-text="stripePaymentMethodToUseSubtitle"
        :options="possibleStripePaymentMethods"
        :selected-option="stripePaymentMethodToUse?.id"
        options-type="stripe_payment_methods"
        layout="checkout"
        class="border-b-0"
        @clicked-option="setStripePaymentMethodToUse"
      />
      <div
        v-show="hasPaymentErrors"
        id="card-errors"
        role="alert"
        class="mt-3 text-red-600 font-semibold text-sm rounded-md px-3 text-center"
      >
        {{ paymentErrorMessages[paymentErrorMessages.length - 1] }}
      </div>
      <div class="p-4">
        <PrimaryButton
          @click="payWithExistingMethod(stripePaymentMethodToUse?.attributes.stripe_id)"
          class="w-full"
          :size="'xl'"
          :justify="'justify-center'"
          :streaking="true"
          :active="true"
          :is-loading="pendingPayment"
        >
          Pay {{ chargeableAmountCents() }} with {{ stripePaymentMethodToUseTitle }}
        </PrimaryButton>
        <SecondaryButton @click="addADifferentPaymentMethod = true" class="w-full mt-2" :size="'xl'">
          Add a different payment method
        </SecondaryButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mapRelationship from '@/utils/mapRelationship'
import InfoRow from '@/components/Checkout/InfoRow'
import TippingSection from '@/components/Checkout/TippingSection'
import PrimaryButton from '@/components/Molecules/PrimaryButton'
import SecondaryButton from '../Molecules/SecondaryButton.vue'
import DiscountCodeModule from '@/components/Checkout/DiscountCodeModule'

export default {
  name: 'PaymentSection',
  components: {
    InfoRow,
    TippingSection,
    PrimaryButton,
    SecondaryButton,
    DiscountCodeModule
  },
  props: ['checkout', 'hasDiscountCodeBox'],
  data: () => ({
    readyToPay: false,
    stripe: null,
    elements: null,
    showBrowserPaymentMethod: false,
    pendingPayment: false,
    selectedStripePaymentMethod: null,
    showExistingPaymentMethodsFlow: true,
    addADifferentPaymentMethod: false,
    paymentErrorMessages: [],
    paymentElement: null,
    paymentRequest: null,
    paymentRequestButton: null,
    applePaySupported: false
  }),
  computed: {
    ...mapGetters(['info']),
    showTippingSection() {
      return this.bottle?.attributes?.allow_tipping && !this.pendingPayment
    },
    bottle() {
      return this.$store.getters.currentBottle
    },
    stripeToken() {
      return this.checkout.attributes.stripe_token
    },
    canPay() {
      return this.readyToPay
    },
    bottleReceiptLink() {
      return window.location.protocol + '//' + window.location.host + '/b/' + this.bottle?.id + '/receipt'
    },
    bottleCheckoutLink() {
      return window.location.protocol + '//' + window.location.host + '/b/' + this.bottle?.id + '/checkout'
    },
    possibleStripePaymentMethods() {
      return this.$store.getters.stripePaymentMethods(this.bottle?.relationships?.possible_stripe_payment_methods?.data)
    },
    stripePaymentMethodToUse() {
      return this.selectedStripePaymentMethod || this.possibleStripePaymentMethods?.[0]
    },
    stripePaymentMethodToUseTitle() {
      const brand = this.stripePaymentMethodToUse?.attributes?.brand?.toString()
      return (
        '*' +
        this.stripePaymentMethodToUse?.attributes?.last4?.toString() +
        ' ' +
        brand?.charAt(0).toUpperCase() +
        brand?.slice(1)
      )
    },
    stripePaymentMethodToUseSubtitle() {
      return (
        (this.stripePaymentMethodToUse?.attributes?.name ? this.stripePaymentMethodToUse?.attributes?.name + ' ' : '') +
        this.stripePaymentMethodToUse?.attributes?.exp_month +
        '/' +
        this.stripePaymentMethodToUse?.attributes?.exp_year
      )
    },
    stripePaymentIntentClientSecret() {
      return this.checkout?.attributes.stripe_payment_intent_client_secret
    },
    checkoutAmount() {
      return this.checkout?.attributes.chargeable_amount
    },
    showStripeElements() {
      return this.stripePaymentIntentClientSecret !== null
    },
    ccName() {
      return document.getElementById('ccname').value
    },
    showExistingStripePaymentMethods() {
      return this.hasExistingStripePaymentMethods && !this.addADifferentPaymentMethod
    },
    hasExistingStripePaymentMethods() {
      return this.possibleStripePaymentMethods?.length > 0
    },
    showNewPaymentOptionPayButton: function () {
      return this.paymentOption == 'newCard'
    },
    enableNewPaymentOptionPayButton: function () {
      return this.card !== null
    },
    showRequestButtonPaymentMethod() {
      return this.showBrowserPaymentMethod && (this.addADifferentPaymentMethod || !this.hasExistingStripePaymentMethods)
    },
    showElementsPaymentMethod() {
      return this.addADifferentPaymentMethod || !this.hasExistingStripePaymentMethods
    },
    showPaymentNoticeBox() {
      return this.hasCheckoutErrors
    },
    hasCheckoutErrors() {
      return (
        this.checkout?.attributes?.next_action == 'fix-errors' && this.checkout?.attributes?.checkout_errors?.length > 0
      )
    },
    showMarkAsPaidBox() {
      return this.shouldMarkAsPaid && !this.showPaymentNoticeBox
    },
    shouldMarkAsPaid() {
      return this.checkout?.attributes?.next_action == 'mark-paid'
    },
    checkoutNotice() {
      if (this.pendingPayment) {
        return 'Submitting payment...'
      } else if (this.checkout?.attributes?.checkout_errors?.length > 0) {
        return this.checkout?.attributes?.checkout_errors?.[0] + '.'
      } else {
        return 'This order cannot be paid for.'
      }
    },
    hasPaymentErrors() {
      return this.paymentErrorMessages.length > 0
    },
    facebookPixelContainerID() {
      return this.info?.attributes?.facebook_pixel_container_id
    }
  },
  watch: {
    stripePaymentIntentClientSecret: function (newSecret, oldSecret) {
      if (newSecret) {
        this.createOrUpdateStripeObject()
        this.createOrUpdateStripeElements()
        this.createOrUpdatePaymentElement()
      }
    },
    checkoutAmount: function (newAmount, oldAmount) {
      if (newAmount?.cents !== oldAmount?.cents) {
        this.updatePaymentElement()
      }
    }
  },
  mounted() {
    this.createOrUpdateStripeObject()
    this.createOrUpdateStripeElements()
    this.createOrUpdatePaymentElement()
  },
  methods: {
    chargeableAmountCents() {
      return this.checkout?.attributes?.chargeable_amount?.format
    },
    createOrUpdateStripeObject() {
      this.stripe = window.Stripe(this.stripeToken, { apiVersion: '2019-08-14' })
    },
    createOrUpdateStripeElements() {
      if (this.stripePaymentIntentClientSecret) {
        const options = {
          clientSecret: this.stripePaymentIntentClientSecret
        }
        this.elements = this.stripe.elements(options)
      }
    },
    createOrUpdatePaymentElement() {
      if (!this.paymentElement && this.elements) {
        // payment element
        this.paymentElement = this.elements.create('payment', { wallets: { applePay: 'never', googlePay: 'never' } })
        this.paymentElement.mount(this.$refs.payment)

        this.paymentElement.on('change', (event) => {
          if (event.complete) {
            this.readyToPayChange(true)
          } else {
            this.readyToPayChange(false)
          }
        })

        // payment request button
        this.paymentRequest = this.stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: 'Total',
            amount: this.checkoutAmount?.cents > 0 ? this.checkoutAmount?.cents : 50
          },
          requestPayerName: true,
          requestPayerEmail: false
        })
        // Create the paymentRequestButton Element
        this.paymentRequestButton = this.elements.create('paymentRequestButton', {
          paymentRequest: this.paymentRequest
        })
        // Check if the browser supports Apple Pay
        this.paymentRequest.canMakePayment().then((result) => {
          if (result) {
            this.applePaySupported = true
            this.paymentRequestButton.mount(this.$refs.paymentrequestbutton)
          }
        })
        // Set up an event listener for the paymentmethod event
        this.paymentRequest.on('paymentmethod', async (event) => {
          this.payUsingPaymentRequestButton(event)
        })
      }
    },
    updatePaymentElement() {
      if (this.paymentRequestButton) {
        this.paymentRequestButton.destroy()
        this.paymentRequestButton = null
      }
      if (this.paymentElement) {
        this.paymentElement.destroy()
        this.paymentElement = null
        this.createOrUpdateStripeElements()
        this.createOrUpdatePaymentElement()
      }
    },
    readyToPayChange(value) {
      this.readyToPay = value
    },
    setStripePaymentMethodToUse(newMethod) {
      this.selectedStripePaymentMethod = this.$store.state.objects.stripe_payment_method?.[newMethod]
    },

    // different pay types
    async pay() {
      if (this.canPay) {
        this.pendingPayment = true
        const validationResponse = await this.$store.dispatch('bottleValidation', this.bottle.id)
        if (validationResponse.should_charge && validationResponse.can_charge) {
          this.payWithElement()
        } else if (validationResponse.chargeable_amount_cents_changed) {
          this.pendingPayment = false
          this.paymentErrorMessages = ['The amount to be charged has changed, please review your order one more time.']
        } else {
          this.pendingPayment = false
          this.paymentErrorMessages = validationResponse.errors
        }
      }
    },
    async markAsPaid() {
      this.pendingPayment = true
      const validationResponse = await this.$store.dispatch('bottleValidation', this.bottle.id)
      if (validationResponse.should_charge && validationResponse.can_charge) {
        await this.$store.dispatch('markBottleAsPaid')
        this.handleTracking()
        this.advanceToReceipt()
      } else if (validationResponse.chargeable_amount_cents_changed) {
        this.pendingPayment = false
        this.paymentErrorMessages = ['The amount to be charged has changed, please review your order one more time.']
      } else {
        this.pendingPayment = false
        this.paymentErrorMessages = validationResponse.errors
      }
    },
    async payWithElement() {
      this.pendingPayment = true
      const elements = this.elements
      const link = this.bottleCheckoutLink
      try {
        const { error } = await this.stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: link
          },
          redirect: 'if_required'
        })
        if (error) {
          this.pendingPayment = false
          this.paymentErrorMessages.push(error.message)
        } else {
          this.handleTracking()
          this.advanceToReceipt()
        }
      } catch (error) {
        this.pendingPayment = false
        this.paymentErrorMessages.push(error?.message)
      }
    },
    async payWithExistingMethod(existingMethodID) {
      this.pendingPayment = true
      const validationResponse = await this.$store.dispatch('bottleValidation', this.bottle.id)
      if (validationResponse.should_charge && validationResponse.can_charge) {
        this.confirmCardPayment({
          payment_method: existingMethodID
        })
      } else if (validationResponse.chargeable_amount_cents_changed) {
        this.pendingPayment = false
        this.paymentErrorMessages = ['The amount to be charged has changed, please review your order one more time.']
      } else {
        this.pendingPayment = false
        this.paymentErrorMessages = validationResponse.errors
      }
    },
    async payUsingPaymentRequestButton(event) {
      this.pendingPayment = true
      const validationResponse = await this.$store.dispatch('bottleValidation', this.bottle.id)
      if (validationResponse.should_charge && validationResponse.can_charge) {
        this.confirmCardPayment(
          {
            payment_method: event.paymentMethod.id
          },
          event
        )
      } else if (validationResponse.chargeable_amount_cents_changed) {
        this.pendingPayment = false
        this.paymentErrorMessages = ['The amount to be charged has changed, please review your order one more time.']
        event.complete('fail')
      } else {
        this.pendingPayment = false
        this.paymentErrorMessages = validationResponse.errors
        event.complete('fail')
      }
    },
    confirmCardPayment(paymentMethodBlock, event = null) {
      this.pendingPayment = true

      this.stripe.confirmCardPayment(this.stripePaymentIntentClientSecret, paymentMethodBlock).then((result) => {
        // Handle result.error or result.paymentIntent
        if (result.error) {
          this.pendingPayment = false
          this.paymentErrorMessages.push(result.error.message)
          if (event) {
            event.complete('fail')
          }
        } else if (result?.paymentIntent?.status === 'requires_action') {
          this.confirmCardPayment({})
        } else {
          this.advanceToReceipt()
          this.handleTracking()
          if (event) {
            event.complete('success')
          }
        }
      })
    },
    // end of different payment types

    updateDiscount(data) {
      this.$store.dispatch('addDiscountCode', data)
    },
    removeDiscount(data) {
      this.$store.dispatch('removeAppliedDiscountCode', data)
    },

    advanceToReceipt() {
      this.$router.push({ name: 'Receipt' })
    },
    handleTracking() {
      window.bottle.hooks.v1.emit('purchase', {
        currency: this.$store.getters.checkout?.attributes.bottle_total.currency_iso,
        value: this.$store.getters.checkout?.attributes.bottle_total.cents / 100,
        transaction_id: this.$store.getters.currentBottle?.attributes?.display_id,
        items: this.$store.getters.cartItems.map((x) => ({
          item_id: x.attributes.id,
          item_name: mapRelationship(this.$store.state, x.relationships.product.data).attributes.product_name,
          price: x.attributes.total_price.cents / 100,
          quantity: x.attributes.quantity
        }))
      })
      if (this.facebookPixelContainerID) {
        window.fbq('track', 'Purchase', {
          currency: this.checkout?.attributes?.bottle_total?.currency_iso,
          value: this.checkout?.attributes?.bottle_total?.cents / 100
        })
      }
    }
  }
}
</script>
